import React, { useEffect } from "react";

import { Redirect, Switch, useHistory } from "react-router-dom";

import SignIn from "../pages/SignIn";
import Dashboard from "../pages/Dashboard";
import ContactForm from "../pages/ContactForm";
import ForgotPassword from "../pages/ForgotPassword";
import UpdateUser from "../pages/UpdateUser";
import Faq from "../pages/Faq";

import {
  loadUser,
  logoutRequest,
  preLogoutRequest,
} from "../redux/modules/auth/actions";
import { getNotifications } from "../redux/modules/notification/actions";
import { loadLanguagePreference } from "../redux/modules/language/actions";
import { alertReset } from "../redux/modules/alerts/actions";
import { useToast } from "../hooks/toast";

import { useDispatch, useSelector } from "react-redux";

import Route from "./Route";
import { useIntl } from "react-intl";
import FaqCreation from "../pages/Faq/Create";
import FaqEdition from "../pages/Faq/Edit";
import FaqVisualization from "../pages/Faq/Visualization";
import Events from "../pages/Events";
import Checklist from "../pages/Checklist";
import ChecklistItem from "../pages/Checklist/ChecklistItem"
import EventDetail from "../pages/Events/Detail";
import api from "../service";
import EventClientVisualization from "../pages/Events/ClientVisualization";
import EventClientVisualizationDetail from "../pages/Events/ClientVisualization/Detail";
import Contracts from "../pages/Contracts";
import ContractDetail from "../pages/Contracts/Detail";
import Accounts from "../pages/Accounts";
import ServiceProviders from "../pages/ServiceProviders";
import AccountDetail from "../pages/Accounts/Details";
import Users from "../pages/Users";
import Logs from "../pages/Logs";
import SalesforceUser from "../pages/Salesforce/User";
import AssociateColladorators from "../pages/Collaborators/Associate";
import AssociateColladoratorsDetail from "../pages/Collaborators/Associate/Detail";
import AssociateColladoratorsCreate from "../pages/Collaborators/Associate/Create";
import AdminAssociateCollaborators from "../pages/AdminCollaborators/Associate";
import AdminAssociateCollaboratorsDetail from "../pages/AdminCollaborators/Associate/Detail";
import AdminAssociateCollaboratorsCreate from "../pages/AdminCollaborators/Associate/Create";
import Profiles from "../pages/Profiles";
import NewProfile from "../pages/Profiles/New";
import EditProfile from "../pages/Profiles/Edit";
import ExhibitorOpenID from "../pages/OpenID/Exhibitor";
import ServiceProviderOpenID from "../pages/OpenID/ServiceProvider";
import StaffOpenID from "../pages/OpenID/Staff";
import AdminEditServiceProvider from "../pages/AdminEditUser/ServiceProvider";
import SignInForm from "../pages/SignIn/Form";

import ClientServiceProviderAssociation from "../pages/ServiceProviderAssociation/Client";
import ClientServiceProviderAssociationProviderView from "../pages/ServiceProviderAssociation/Client/ServiceProviderView";
import ClientServiceProviderBoothView from "../pages/ServiceProviderAssociation/Client/ServiceProviderBoothView";
import ClientCreateAssociation from "../pages/ServiceProviderAssociation/Client/CreateAssociation";

import AdminServiceProviderAssociation from "../pages/ServiceProviderAssociation/Admin";
import AdminCreateAssociation from "../pages/ServiceProviderAssociation/Admin/CreateAssociation";

import UsersJourney from "../pages/Accounts/Details/Journey";
import Logout from "../pages/Logout";
import Credentials from "../pages/Credentials";
import ManageGuests from "../pages/ManageGuests";
import NewGuest from "../pages/ManageGuests/New";
import EditGuest from "../pages/ManageGuests/Edit";
import AdminManageGuests from "../pages/AdminManageGuests";
import AdminNewGuest from "../pages/AdminManageGuests/New";
import AdminEditGuest from "../pages/AdminManageGuests/Edit";
import ScheduleWithdrawal from "../pages/Credentials/ScheduleWithdrawal";
import EditScheduleWithdrawal from "../pages/Credentials/EditScheduleWithdrawal";
import ManageCredentials from "../pages/ManageCredentials";
import AdminGuestsReport from "../pages/AdminGuestsReport";
import AdditionalProducts from "../pages/AdditionalProducts";
import NewAdditionalProducts from "../pages/AdditionalProducts/New";
import EditAdditionalProducts from "../pages/AdditionalProducts/Edit";
import ProductEventAssociation from "../pages/ProductEventAssociation";
import NewProductEventAssociation from "../pages/ProductEventAssociation/New";
import EditProductEventAssociation from "../pages/ProductEventAssociation/Edit";
import Category from "../pages/Category";
import NewCategory from "../pages/Category/New";
import EditCategory from "../pages/Category/Edit";
import AuthSSO from "../pages/AuthSSO";
import { useLoading } from "../hooks/loading";
import Cockpit from "../pages/Cockpit";
import ManageCredentialReport from "../pages/AdminCredentialReport";
import ManageCredentialScheduleReport from "../pages/AdminCredentialScheduleReport";
import EventSetupParameters from "../pages/EventSetup/Parameters";
import EventSetup from "../pages/EventSetup";
import EventSetupCreate from "../pages/EventSetup/Create";
import EventSetupEdit from "../pages/EventSetup/Edit";
import ManageAdminExhibitorsAccessByEventReport from "../pages/AdminExhibitorsAccessByEventReport";
import ManageExhibitorsDidNotCreateCredentialsReport from "../pages/AdminExhibitorsDidNotCreateCredentialsReport";
import ManageServiceProvidersAssociatedReport from "../pages/AdminServiceProvidersAssociatedReport";
import ManageDigitalProductsReport from "../pages/AdminDigitalProductsReport";
import CredentialTransfer from "../pages/CredentialTransfer";
import NewCredentialTransfer from "../pages/CredentialTransfer/Create";
import AuthSSOError from "../pages/AuthSSOError";
import ManageHeadbandReport from "../pages/AdminHeadbandReport";
import EventSetupHelpdesk from "../pages/AdminEventSetupHelpdesk";
import StaffAccess from "../pages/StaffAccess";
import NewStaffAccess from "../pages/StaffAccess/New";
import EditStaffAccess from "../pages/StaffAccess/Edit";
import ManageOrderReport from "../pages/AdminOrderReport";
import NotFound from "../pages/NotFound";
import ManageServiceProvidersReport from "../pages/AdminServiceProvidersReport";
import ImpersonateUser from "../pages/ImpersonateUser";
import ManageExhibitorsAssociatedCredentialsSyntheticReport from "../pages/AdminExhibitorsAssociatedCredentialsSyntheticReport";
import ManageCustomersJourneyReport from "../pages/AdminCustomersJourneyReport";
import UpdateRegistrationDataSerpro from "../pages/UpdateRegistrationDataSerpro";
import AdminCredentialTransfer from "../pages/AdminCredentialTransfer";
import AdminCreateCredentialTransfer from "../pages/AdminCredentialTransfer/Create";
import ManageFilteredSalesforceEventEditions from "../pages/SalesforceEventEditions";
import CreateFilteredSalesforceEventEditions from "../pages/SalesforceEventEditions/Create";
import EditFilteredSalesforceEventEditions from "../pages/SalesforceEventEditions/Edit";
import ManageSwapcardIntegrationReport from "../pages/AdminSwapcardIntegrationReport";
import ConsultProviderByEvent from "../pages/ConsultProviderByEvent";
import ChecklistView from "../pages/ChecklistView";
import AccountJourney from "../pages/AccountJourney";
import Invoices from "../pages/Invoices";

const Routes = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.alerts);
  const { user, token, accountCreated, executeLogout } = useSelector(
    (state) => state.auth
  );
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();
  const { debouncing, isAccountLoading, handleAccountLoading, handleLoading } =
    useLoading();

  useEffect(() => {
    if (message.isDialog) {
      addToast({
        type: message.messageType,
        title: intl.formatMessage({
          id: message.title,
        }),
        description: intl.formatMessage({
          id: message.message,
        }),
      });
      dispatch(alertReset());
    }
  }, [message, addToast, intl, dispatch]);

  useEffect(() => {
    dispatch(loadUser());
    dispatch(loadLanguagePreference());
  }, [dispatch]);

  useEffect(() => {
    if (executeLogout) {
      dispatch(
        logoutRequest({
          token,
          callbackLoading: handleLoading,
          tokenExpired: true,
          user: user,
        })
      );
    }
  }, [dispatch, executeLogout, handleLoading, token, user]);

  useEffect(() => {
    if (!user && history.location.pathname === "/") {
      const script = document.createElement('script');

      script.src = process.env.REACT_APP_WEBCHAT;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [user]);

  const UNAUTHORIZED = 401;
  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === UNAUTHORIZED) {
          if (isAccountLoading && debouncing) {
            handleAccountLoading(false);
            return Promise.reject(error);
          }
          handleLoading(true);
          dispatch(preLogoutRequest());
        }

        return Promise.reject(error);
      }
    );
  }, [
    user,
    history.location.pathname,
    dispatch,
    handleLoading,
    executeLogout,
    token,
    isAccountLoading,
    debouncing,
    handleAccountLoading,
  ]);

  useEffect(() => {
    if (user && user?.role?.type !== "EXHIBITOR") {
      if (!accountCreated) {
        if (history.location.pathname !== "/logout") {
          history.push("/update-user");
        }
      }
    }

    if (user && history.location.pathname !== "/logout") {
      dispatch(
        getNotifications({
          token,
        })
      );
    }

    return history.listen((location) => {
      if (
        location.pathname !== "/update-user" &&
        location.pathname !== "/" &&
        location.pathname !== "/logout"
      ) {
        if (user) {
          if (!isAccountLoading) {
            dispatch(
              getNotifications({
                token,
              })
            );
          }
          if (user?.role?.type !== "EXHIBITOR") {
            if (!accountCreated) {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "complete_your_data",
                }),
              });
              history.push("/update-user");
            }
          }
        }
      }
    });
  }, [user, accountCreated, history, addToast, intl, dispatch, token]);

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/signin-form" exact component={SignInForm} />

      <Route
        path="/dashboard"
        component={Dashboard}
        isPrivate
        code="ACCESS_KNOWLEDGE_BASE"
      />

      <Route
        path="/checklist"
        component={ChecklistView}
        isPrivate
      />

      <Route
        path="/contact"
        component={ContactForm}
        isPrivate
        roles={["ADMINISTRATOR"]}
      />

      <Route
        path="/faq"
        exact
        component={Faq}
        isPrivate
        roles={["ADMINISTRATOR"]}
      />

      <Route
        path="/faq/create"
        component={FaqCreation}
        isPrivate
        roles={["ADMINISTRATOR"]}
      />

      <Route
        path="/faq/edit/:id"
        component={FaqEdition}
        isPrivate
        roles={["ADMINISTRATOR"]}
      />

      <Route
        path="/update-user"
        component={UpdateUser}
        isPrivate
        roles={["SERVICE_PROVIDER"]}
      />

      <Route
        path="/faq-visualization"
        component={FaqVisualization}
        isPrivate
      />

      <Route
        path="/events"
        exact
        component={Events}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/events/:id"
        component={EventDetail}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/events-exhibitor-visualization"
        exact
        component={EventClientVisualization}
        isPrivate
      />

      <Route
        path="/events-exhibitor-visualization/:id"
        component={EventClientVisualizationDetail}
        isPrivate
      />

      <Route
        path="/event-checklist/:id"
        exact
        component={Checklist}
        isPrivate
      />

      <Route
        path="/event-checklist/:id/item/create"
        exact
        component={ChecklistItem}
        isPrivate
      />

      <Route
        path="/event-checklist/:id/item/edit/:itemId"
        exact
        component={ChecklistItem}
        isPrivate
      />

      <Route path="/accounts" exact component={Accounts} isPrivate />

      <Route
        path="/service-providers-management"
        exact
        component={ServiceProviders}
        isPrivate
      />

      <Route path="/account/:id" component={AccountDetail} isPrivate />

      <Route path="/users" component={Users} isPrivate />

      <Route path="/logs" component={Logs} isPrivate />

      <Route
        path="/salesforce/user"
        exact
        component={SalesforceUser}
        roles={["EXHIBITOR"]}
        isPrivate
      />

      <Route
        path="/serviceprovider-association"
        exact
        component={ClientServiceProviderAssociation}
        roles={["EXHIBITOR"]}
        isPrivate
      />

      <Route
        path="/serviceprovider/association-provider-view"
        exact
        component={ClientServiceProviderAssociationProviderView}
        isPrivate
      />

      <Route
        path="/serviceprovider-association-provider-detail"
        component={ClientServiceProviderBoothView}
        isPrivate
      />

      <Route
        path="/serviceprovider-association/new"
        exact
        component={ClientCreateAssociation}
        roles={["EXHIBITOR"]}
        isPrivate
      />

      <Route
        path="/admin/serviceprovider-association"
        exact
        component={AdminServiceProviderAssociation}
        roles={["ADMINISTRATOR", "STAFF"]}
        isPrivate
      />

      <Route
        path="/admin/serviceprovider-association/new"
        exact
        component={AdminCreateAssociation}
        roles={["ADMINISTRATOR", "STAFF"]}
        isPrivate
      />

      <Route
        path="/associate-collaborators"
        exact
        component={AssociateColladorators}
        isPrivate
      />

      <Route
        path="/associate-collaborator/edit/:id"
        exact
        component={AssociateColladoratorsDetail}
        isPrivate
      />

      <Route
        path="/credentials/new"
        exact
        component={AssociateColladoratorsCreate}
        isPrivate
      />

      <Route
        path="/admin/associate-collaborators"
        exact
        component={AdminAssociateCollaborators}
        isPrivate
      />

      <Route
        path="/admin/associate-collaborator/edit/:id"
        exact
        component={AdminAssociateCollaboratorsDetail}
        isPrivate
      />

      <Route
        path="/manage-credentials/new"
        exact
        component={AdminAssociateCollaboratorsCreate}
        isPrivate
      />

      <Route
        path="/admin/impersonate-user"
        exact
        component={ImpersonateUser}
        isPrivate
      />

      <Route path="/logout" component={Logout} isPrivate />

      <Route path="/profiles" exact component={Profiles} isPrivate />

      <Route path="/profiles/new" component={NewProfile} isPrivate />

      <Route path="/profile/:id" component={EditProfile} isPrivate />

      <Route path="/credentials" exact component={Credentials} isPrivate />

      <Route
        path="/credential/schedule-withdrawal"
        exact
        component={ScheduleWithdrawal}
        isPrivate
      />

      <Route
        path="/credential/schedule-withdrawal/edit/:id"
        component={EditScheduleWithdrawal}
        isPrivate
      />

      <Route
        path="/admin/service-provider-edit/:id"
        roles={["ADMINISTRATOR", "STAFF"]}
        component={AdminEditServiceProvider}
        isPrivate
      />

      <Route path="/forgot-password" component={ForgotPassword} />

      <Route
        path="/contracts"
        exact
        component={Contracts}
        roles={["ADMINISTRATOR", "STAFF"]}
        isPrivate
      />

      <Route path="/contract/:id" component={ContractDetail} isPrivate />

      <Route
        path="/auth/exhibitor-openid-signin"
        exact
        component={ExhibitorOpenID}
      />

      <Route
        path="/auth/3rdparth-openid-signin"
        exact
        component={ServiceProviderOpenID}
      />

      <Route path="/auth/staff-openid-signin" exact component={StaffOpenID} />

      <Route path="/auth/informa-sso-signin" exact component={AuthSSO} />

      <Route path="/auth/informa-sso-error" exact component={AuthSSOError} />

      <Route path="/cockpit" component={Cockpit} isPrivate />

      <Route
        path="/admin/search-journey"
        component={AccountJourney}
        isPrivate
      />

      <Route
        path="/admin/manage-guests"
        exact
        component={AdminManageGuests}
        isPrivate
      />

      <Route
        path="/admin/manage-guests/new"
        exact
        component={AdminNewGuest}
        isPrivate
      />

      <Route
        path="/admin/manage-guests/:id"
        component={AdminEditGuest}
        isPrivate
      />

      <Route
        path="/manage-credentials"
        exact
        component={ManageCredentials}
        isPrivate
      />

      <Route
        path="/admin/guests-report"
        exact
        component={AdminGuestsReport}
        isPrivate
      />

      <Route
        path="/admin/credential-report"
        exact
        component={ManageCredentialReport}
        isPrivate
      />

      <Route
        path="/admin/credential-schedule-report"
        exact
        component={ManageCredentialScheduleReport}
        isPrivate
      />

      <Route
        path="/admin/additional-products"
        exact
        component={AdditionalProducts}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/additional-products/new"
        exact
        component={NewAdditionalProducts}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/additional-products/:id"
        exact
        component={EditAdditionalProducts}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/categories"
        exact
        component={Category}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/categories/new"
        exact
        component={NewCategory}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/categories/:id"
        exact
        component={EditCategory}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/product-event-association"
        exact
        component={ProductEventAssociation}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/product-event-association/new"
        exact
        component={NewProductEventAssociation}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/product-event-association/:id"
        exact
        component={EditProductEventAssociation}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/event-setup"
        exact
        component={EventSetup}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/exhibitors-access-by-event-report"
        exact
        component={ManageAdminExhibitorsAccessByEventReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/event-setup/new"
        exact
        component={EventSetupCreate}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/exhibitors-didnt-create-credential-report"
        exact
        component={ManageExhibitorsDidNotCreateCredentialsReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/event-setup/:id"
        exact
        component={EventSetupEdit}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/providers-associated-report"
        exact
        component={ManageServiceProvidersAssociatedReport}
        roles={["ADMINISTRATOR", "STAFF"]}
        isPrivate
      />

      <Route
        path="/admin/digital-products-report"
        exact
        component={ManageDigitalProductsReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/event-setup/parameters/:id"
        exact
        component={EventSetupParameters}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/headband-report"
        exact
        component={ManageHeadbandReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/credential-transfer"
        exact
        component={CredentialTransfer}
        isPrivate
      />

      <Route
        path="/credential-transfer/new"
        exact
        component={NewCredentialTransfer}
        isPrivate
      />

      <Route
        path="/admin/event-setup-helpdesk"
        exact
        component={EventSetupHelpdesk}
        isPrivate
      />

      <Route path="/staff-access" exact component={StaffAccess} isPrivate />
      <Route
        path="/staff-access/new"
        exact
        component={NewStaffAccess}
        isPrivate
      />
      <Route path="/staff-access/:id" component={EditStaffAccess} isPrivate />

      <Route
        path="/admin/service-providers-report"
        exact
        component={ManageServiceProvidersReport}
        isPrivate
      />

      <Route
        path="/admin/order-report"
        exact
        component={ManageOrderReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/exhibitors-associated-credential-synthetic"
        exact
        component={ManageExhibitorsAssociatedCredentialsSyntheticReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/action-control-and-event-indicators-report"
        exact
        component={ManageCustomersJourneyReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/update-registration-data"
        exact
        component={UpdateRegistrationDataSerpro}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin-credential-transfer"
        exact
        component={AdminCredentialTransfer}
        isPrivate
      />

      <Route
        path="/admin-credential-transfer/new"
        exact
        component={AdminCreateCredentialTransfer}
        isPrivate
      />

      <Route
        path="/manage-salesforce-event-editions"
        exact
        component={ManageFilteredSalesforceEventEditions}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/manage-salesforce-event-editions/new"
        exact
        component={CreateFilteredSalesforceEventEditions}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/manage-salesforce-event-editions/:id"
        exact
        component={EditFilteredSalesforceEventEditions}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/admin/digital-platform-integration-report"
        exact
        component={ManageSwapcardIntegrationReport}
        roles={["ADMINISTRATOR"]}
        isPrivate
      />

      <Route
        path="/consult-provider-by-event"
        exact
        component={ConsultProviderByEvent}
        isPrivate
      />

      <Route
        path="/invoices"
        exact
        component={Invoices}
        isPrivate
      />

      <Route path="/not-found" isPrivate component={NotFound} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
