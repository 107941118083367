import React from "react";
import ReactExport from "react-data-export";
import { parse } from "date-fns";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, intl, children }) => (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="cnpj_passaporte" value="accountCode" />
      <Column label="razao_social" value="accountName" />
      <Column label="nome_fantasia" value="accountAlias" />
      <Column
        label="tipo"
        value={(col) =>
          col.accountIdNumber
            ? intl.formatMessage({ id: "exhibitor" })
            : intl.formatMessage({ id: "service_provider" })
        }
      />
      <Column
        label="data"
        value={(col) =>
          intl.formatDate(parse(col.date, "yyyy-MM-dd", new Date()))
        }
      />
      <Column label="hora" value="hour" />
      <Column label="quantidade_credenciais_agendadas" value="quantity" />
      <Column
        label="quantidade_credenciais_retiradas"
        value="withdrawalQuantity"
      />
      <Column
        label="credenciais_foram_todas_retiradas"
        value={(col) =>
          col.quantity === col.withdrawalQuantity
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" })
        }
      />
      <Column label="nome_quem_retira" value="whoWithdrawsName" />
      <Column label="contato" value="whoWithdrawsPhone" />
    </Sheet>
  </File>
);

export default Report;
