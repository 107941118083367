import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  Horizontal,
  TableHeader,
  LinkButton,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import api from "../../../service";

import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import { fillTimePickerBetween, getIntervalHours } from "../../../utils/fillTimepicker";
import Table from "../../../components/Table";
import { inputTimeInsideDate, listDatesBetween } from "../../../utils/date";
import { useLoading } from "../../../hooks/loading";

import { parse } from "date-fns";
import
  TelephoneInputWithLabel,
  { getFullTelephoneNumber }
from "../../../components/TelephoneInputWithLabel";

const ScheduleWithdrawal = () => {
  const todayTime = new Date().getTime();

  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [eventId, setEventId] = useState();
  const [events, setEvents] = useState();
  const [contractId, setContractId] = useState();
  const [contracts, setContracts] = useState();

  const [enableFields, setEnableFields] = useState(false);

  const [timePickerOptions, setTimePickerOptions] = useState();
  const [firstDayExcludedHours, setFirstDayExcludedHours] = useState([])
  const [filteredTimePickerOptions, setFilteredTimePickerOptions] = useState();
  const [dateOptions, setDateOptions] = useState();
  const [firstDay, setFirstDay] = useState()
  const [credentials, setCredentials] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const [schedules, setSchedules] = useState([]);

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    date: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    time: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name_of_who_withdraws: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    contact: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            `/credential-schedule`,
            {
              eventId: data.event,
              date: inputTimeInsideDate({ time: data.time, date: data.date }),
              nameOfwhoWithdraws: data.name_of_who_withdraws,
              contact: getFullTelephoneNumber(
                data.contact, data.contact_selected_country),
              contractId: data.contract,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.schedule_creation",
              }),
            });
            history.push("/credentials");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, schema, token, loading]
  );

  useEffect(() => {
    if (eventId && contractId) {
      handleLoading(true);
      api
        .patch(
          `/credential-schedule`,
          {
            eventId: eventId,
            contractId: contractId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const {
            credentialOutputList,
            credentialScheduleOutput,
          } = result.data;
          setEnableFields(true);
          setTimePickerOptions(
            fillTimePickerBetween(
              credentialScheduleOutput.answeringTimeInterval,
              credentialScheduleOutput.startTimeToScheduleCredentials,
              credentialScheduleOutput.endTimeToScheduleCredentials
            )
          );
          setFirstDayExcludedHours(
            [...getIntervalHours(
              credentialScheduleOutput.answeringTimeInterval,
              credentialScheduleOutput.startTimeToScheduleCredentials,
              credentialScheduleOutput.firstDayStartTimeToScheduleCredentials
            ), ...getIntervalHours(
              credentialScheduleOutput.answeringTimeInterval,
              credentialScheduleOutput.firstDayEndTimeToScheduleCredentials,
              credentialScheduleOutput.endTimeToScheduleCredentials
            )]
          )
          setDateOptions(
            listDatesBetween(
              new Date(
                credentialScheduleOutput.startDateForSchedulingCredentials
              ),
              new Date(
                credentialScheduleOutput.endDateForSchedulingCredentials
              ),
              true
            )
          );
          setFirstDay(credentialScheduleOutput.startDateForSchedulingCredentials)
          setCredentials(credentialOutputList);
        })
        .catch((err) => {
          setEnableFields(false);
          formRef.current.reset();
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => handleLoading(false));
    }
  }, [eventId, addToast, intl, token, handleLoading, contractId]);

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/exhibitor/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => setEvents(result.data.output))
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (eventId) {
      handleLoading(true);
      api
        .get(`/exhibitor/opportunity/event/${eventId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => setContracts(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [eventId, addToast, intl, token, handleLoading]);

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/manage-credential-schedule`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) =>
        setSchedules(result.data.credentialScheduleReportResponse)
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  const handleDateChange = (e) => {
    formRef.current?.setFieldValue("time");
    const date = e.value.toISOString().split("T")[0];
    const hours = schedules.reduce((hours, schedule) => {
      if (schedule.date === date) {
        hours.push(schedule.hour.substring(0, schedule.hour.lastIndexOf(":")));
      }
      return hours;
    }, []);

    const firstDayHours = date === firstDay ? firstDayExcludedHours : []

    const formattedHours = [...hours, ...firstDayHours]

    setFilteredTimePickerOptions(
      timePickerOptions.filter((option) => !formattedHours.includes(option.value))
    );
  };

  const handleDeleteSchedule = useCallback(
    (id) => {
      handleLoading(true);
      api
        .delete(`/credential-schedule/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.schedule_withdrawal_delete",
            }),
          });

          setSchedules((schedules) =>
            schedules.filter((element) => element.scheduleId !== id)
          );
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, handleLoading, intl, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "name" }),
        selector: "collaboratorName",
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: "jobTitle",
      },
    ],
    [intl]
  );

  const columnsSchedulesTable = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event" }),
        selector: (row) => row.eventName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
      },
      {
        name: intl.formatMessage({ id: "created_at" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(parse(row.createdDate, "yyyy-MM-dd", new Date())),
      },
      {
        name: intl.formatMessage({ id: "withdraw_date" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(parse(row.date, "yyyy-MM-dd", new Date())),
      },
      {
        name: intl.formatMessage({ id: "schedule_time" }),
        selector: (row) => row.hour,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "name_of_who_withdraws" }),
        selector: (row) => row.whoWithdrawsName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
      },
      {
        name: intl.formatMessage({ id: "is_active" }),
        cell: (row) =>
          row.scheduleActive
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
        minWidth: "200px",
      },
      {
        cell: (row) => (
          <>
            <LinkButton
              to={`/credential/schedule-withdrawal/edit/${row.scheduleId}`}
            >
              <Badge
                color="#002244"
                text={intl.formatMessage({ id: "reschedule" })}
              />
            </LinkButton>
            {new Date(row.date).getTime() > todayTime && (
              <Badge
                onClick={() => handleDeleteSchedule(row.scheduleId)}
                color="#cc0000"
                text={intl.formatMessage({ id: "delete" })}
              />
            )}
          </>
        ),
        right: true,
      },
    ],
    [handleDeleteSchedule, intl, todayTime]
  );

  return (
    <AnimatedMainContainer>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="schedule_withdrawal" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/credentials")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "confirm" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={events}
                  onChange={(e) => setEventId(e?.value)}
                  required
                />

                <SelectWithLabel
                  name="contract"
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={contracts}
                  onChange={(e) => setContractId(e?.value)}
                  required
                  disabled={!eventId}
                />
              </Horizontal>

              <Horizontal>
                <SelectWithLabel
                  name="date"
                  placeholder={intl.formatMessage({
                    id: "date",
                  })}
                  label={intl.formatMessage({
                    id: "date",
                  })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={dateOptions}
                  onChange={handleDateChange}
                  disabled={!enableFields}
                  required
                />
                <SelectWithLabel
                  name="time"
                  placeholder={intl.formatMessage({
                    id: "schedule_time",
                  })}
                  clearable={true}
                  label={intl.formatMessage({
                    id: "schedule_time",
                  })}
                  options={filteredTimePickerOptions}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
              </Horizontal>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="name_of_who_withdraws"
                  placeholder={intl.formatMessage({
                    id: "name_of_who_withdraws",
                  })}
                  label={intl.formatMessage({
                    id: "name_of_who_withdraws",
                  })}
                  validationBlur={validationBlur}
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
                <TelephoneInputWithLabel
                  name="contact"
                  placeholder={intl.formatMessage({
                    id: "contact",
                  })}
                  label={intl.formatMessage({
                    id: "contact",
                  })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
              </Horizontal>
              {!enableFields || !contractId ? (
                <div style={{ textAlign: "center" }}>
                  <FormattedMessage id="need_to_filter_first" />
                </div>
              ) : (
                <>
                  <TableHeader>
                    <div>
                      <FormattedMessage id="list_of_associated_credentials" />
                    </div>
                  </TableHeader>
                  <Table
                    data={credentials}
                    columns={columns}
                    hasPagination={false}
                  />
                </>
              )}
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
      <Table
        columns={columnsSchedulesTable}
        data={schedules}
        title={intl.formatMessage({ id: "results" })}
        hasPagination
      />
    </AnimatedMainContainer>
  );
};

export default ScheduleWithdrawal;
