import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { base64ToBlob, downloadBlob } from "../../utils/file";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from "./report";

const ManageCredentialReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState();
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [accounts, setAccounts] = useState([]);
  const [filtered, setFiltered] = useState(false);

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    role: Yup.string().nullable(),
    account: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const roles = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "all" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ],
    [intl]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-credential`,
            {
              eventId: data.event,
              userId: data.account,
              clientType: data.role,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.credentialReportResponse);
            setShowExport(true);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );

        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("account");
    if (selectedEvent && selectedRole !== undefined) {
      handleLoading(true);
      api
        .patch(
          `/users/by-event-and-role`,
          {
            eventId: selectedEvent,
            roleType: selectedRole,
            useEmailInTheServiceProviderLabel: false,
            useCancelledOpportunities: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) =>
          setAccounts([
            { value: 0, label: intl.formatMessage({ id: "all" }) },
            ...result.data.accountDropdownOutputList,
          ])
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [addToast, intl, selectedEvent, selectedRole, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", "");
    formRef.current.setFieldValue("role", "");
    formRef.current.setFieldValue("account", "");
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const onChangeEvent = (event) => {
    setFilename(event?.label?.toUpperCase());
    setSelectedEvent(event?.value);
    setShowExport(false);
    setData([]);
    setFiltered(false);
    formRef.current.setFieldValue("account", "");
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "company_name" }),
        selector: (row) => row.accountName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "commercial_name" }),
        selector: (row) => row.accountAlias,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "id_number_cnpj_passport" }),
        selector: (row) => row.accountCode,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "155px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.credentialType,
        cell: (row) =>
          row.credentialType === "Contrato"
            ? intl.formatMessage({ id: "contract" })
            : row.credentialType,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        maxWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "credential_emission_date" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(
            format(new Date(row.credentialEmissionDate), "yyyy-MM-dd")
          ),
      },
      {
        name: intl.formatMessage({ id: "email" }),
        selector: (row) => row.email,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "accredited_document" }),
        selector: (row) => row.accreditedDoc,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "180px",
      },
      {
        name: intl.formatMessage({ id: "accredited_name" }),
        selector: (row) => row.accreditedName,
        allowOverflow: true,
        wrap: true,
        minWidth: "160px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "accredited_job" }),
        selector: (row) => row.accreditedJob,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "printed_credential" }),
        cell: (row) =>
          row.printed
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "bar_code" }),
        selector: (row) => row.barCode,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "115px",
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credential_report" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_credential_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  onChange={onChangeEvent}
                  options={events}
                  required
                />
                <SelectWithLabel
                  name="role"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                  placeholder={intl.formatMessage({ id: "type" })}
                  labelOrientation="vertical"
                  options={roles}
                  onChange={(role) => setSelectedRole(role?.value)}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="account"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "account" })}
                  placeholder={intl.formatMessage({ id: "account" })}
                  labelOrientation="vertical"
                  labelWidth="150px"
                  options={accounts}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`${filename}_CREDENTIALS_REPORT`}
                          data={data}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageCredentialReport;
