import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithLabel from "../../../components/InputWithLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import { useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { useIntl, FormattedMessage } from "react-intl";
import { MdInfo, MdDescription, MdPerson, MdHome } from "react-icons/md";
import { Form } from "@unform/web";
import Badge from "../../../components/Badge";
import api from "../../../service";
import * as Yup from "yup";

import {
  Container,
  TitleContainer,
  DocumentsContainer,
  DocumentsUploadContainer,
  DocumentUpload,
  DocumentUploadTitle,
  DocumentDragAndDropContainer,
  Horizontal,
  FormButtonContainer,
  Session,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  SessionDivider,
  FieldsContainer,
  BadgeContainer,
} from "./styles";
import { useLocation } from "react-router-dom";
import { convertToDateOnly } from "../../../utils/date";
import {
  DocumentOpenText,
  DocumentVisualization,
} from "../../Events/Detail/styles";
import PDFModal from "../../../components/PDFModal";
import FormButton from "../../../components/FormButton";
import { useLoading } from "../../../hooks/loading";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import getValidationErrors from "../../../utils/getValidationErrors";

const Detail = () => {
  const { token } = useSelector((state) => state.auth);
  const { state } = useLocation();

  const intl = useIntl();
  const { addToast } = useToast();
  const [exhibitorsManualOpen, setExhibitorsManualOpen] = useState(false);
  const [pricingTableOpen, setPricingTableOpen] = useState(false);
  const history = useHistory();
  const { handleLoading } = useLoading();

  const [data, setData] = useState({});
  const [key, setKey] = useState({});

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [boothSelected, setBoothSelected] = useState(null);
  const [boothList, setBoothList] = useState([]);

  useEffect(() => {
    handleLoading(true);
    api
      .post(
        `/serviceprovider/find`,
        {
          provisionType: state?.provisionType,
          opportunityIdNumber: state?.opportunityId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setData(result.data.output);
        setBoothList(result.data.output.booths
            .filter((booth) => booth.boothNumber)
            .map((booth) => ({
                label: booth.boothNumber,
                value: booth.idNumber,
            }))
            .sort((a, b) =>
                a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
        );
        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [
    addToast,
    handleLoading,
    intl,
    state?.provisionType,
    state?.opportunityIdNumber,
    token,
    state?.opportunityId,
  ]);

  /* Alteração de contorno visando resolver problema em produção com AGRISHOW, remover no futuro */
  const isAgrishow = useMemo(()=>{
    if(!data) return false
    if(data.eventEditionCode === "BRZ21FAG" 
      || data.eventEditionCode === "BRZ22FAG" 
      || data.eventEditionCode ===  "BRZ23FAG") 
    return true
    return false
  },[data])

  /* Alteração de contorno visando resolver problema em produção com ABF2023, remover no futuro */
  const isABF2023 = useMemo(()=>{
    if(!data) return false
    if(data.eventEditionCode === "BRZ23ABF") return true
    return false
  },[data])

  const handleLogFileDownload = (eventFileType) => {
    api
      .post(
        `/users/events/log-file-download/${data.eventEditionCode}`,
        {
          fileType: eventFileType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {})
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const handleBoothVisualization = () => {
    const boothId = formRef.current.getFieldValue("booth_select");
    setBoothSelected(null);
    if (boothId === "") {
      return;
    }

    let boothFiltered = data.booths.filter(
      (booth) => booth.idNumber === boothId
    )[0];

    setBoothSelected(boothFiltered);
  };

  const schemaHeadband = Yup.object().shape({
    headband: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "front_sign_error" })),
  });

  const validationBlurHeadband = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schemaHeadband, name, formRef);
  };

  const saveHeadbandOpportunity = useCallback(
    (contractId, headband) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/serviceprovider/opportunity/headband`,
            {
              opportunityId: contractId,
              headband: headband,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.front_sign_opportunity_creation",
              }),
            });
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, token]
  );

  const saveHeadband = () => {
    validationBlurHeadband("headband");
    const headband = formRef.current.getFieldValue("headband");
    const contractId = data.opportunityId;
    if (headband == null || headband === "") {
      return;
    }
    saveHeadbandOpportunity(contractId, headband);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.events" })}
    >
      <Form ref={formRef} key={key} initialData={{ headband: data?.headband }}>
        <Container>
          <TitleContainer>
            <div>{intl.formatMessage({ id: "event_edition_info" })}</div>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "goback" })}
              onClick={() =>
                history.push("/serviceprovider/association-provider-view")
              }
              style={{ cursor: "pointer" }}
            />
          </TitleContainer>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdInfo size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="informations" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  disabled
                  name="event_name"
                  value={data?.eventEditionName}
                  label={intl.formatMessage({ id: "event_name" })}
                />
                <InputWithLabel
                  disabled
                  name="status"
                  value={intl.formatMessage({
                    id: data?.status ? "active" : "inactive",
                  })}
                  label={intl.formatMessage({ id: "status" })}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  disabled
                  name="contract_number"
                  value={data?.contractNumber}
                  label={intl.formatMessage({ id: "contract_number" })}
                />
                <InputWithLabel
                  disabled
                  name="start_date"
                  value={
                    data?.startEventDate
                      ? convertToDateOnly(data?.startEventDate)
                      : null
                  }
                  label={intl.formatMessage({ id: "start_date" })}
                />
                <InputWithLabel
                  disabled
                  name="end_date"
                  value={
                    data?.finishEventDate
                      ? convertToDateOnly(data.finishEventDate)
                      : null
                  }
                  label={intl.formatMessage({ id: "end_date" })}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  disabled
                  name="venue"
                  value={data?.address}
                  label={intl.formatMessage({ id: "venue" })}
                />
                <InputWithLabel
                  disabled
                  name="pavilion"
                  value={boothList[0]?.pavilion}
                  label={intl.formatMessage({ id: "pavilion" })}
                />
              </Horizontal>
            </FieldsContainer>
          </Session>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdDescription size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="documentation" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <DocumentsContainer>
                <DocumentsUploadContainer>
                   {/* Alteração de contorno visando resolver problema em produção com ABF2023, remover no futuro */}
                   {isABF2023 && <DocumentUpload>
                      <DocumentUploadTitle>
                        {intl.formatMessage({ id: "exhibitor_manual" })}
                      </DocumentUploadTitle>
                      <DocumentDragAndDropContainer>
                        <DocumentVisualization>
                          <DocumentOpenText>
                            {data?.contractCurrency === "BRL" ? (
                              <a
                                href={"https://drive.google.com/file/d/1itH9zn4uwU0GEe_QfNO5yosUDCsTXw18/view"}
                                target={"blank"}
                              >
                                <FormattedMessage id="view_file" />
                              </a>
                            ) : (
                              <a
                                href={"https://drive.google.com/file/d/1ce8-4v_PA99u87Q3l8sDDAnqlcaRHWw-/view"}
                                target={"blank"}
                              >
                                <FormattedMessage id="view_file" />
                              </a>
                            )}
                          </DocumentOpenText>
                        </DocumentVisualization>
                      </DocumentDragAndDropContainer>
                    </DocumentUpload>
                  }
                  {!isABF2023 && <DocumentUpload>
                    <DocumentUploadTitle>
                      {intl.formatMessage({ id: "exhibitor_manual" })}
                    </DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      <DocumentVisualization>
                        <DocumentOpenText
                          onClick={() => {
                            if (data.exhibitorManualUrl) {
                              if (data?.contractCurrency === "BRL") {
                                handleLogFileDownload("EXHIBITOR_MANUAL_PT");
                              } else {
                                handleLogFileDownload("EXHIBITOR_MANUAL_EN");
                              }
                              setExhibitorsManualOpen(true);
                            }
                          }}
                        >
                          {}
                          {data.exhibitorManualUrl ? (
                            <FormattedMessage id="view_file" />
                          ) : (
                            <FormattedMessage id="file_is_not_configured" />
                          )}
                        </DocumentOpenText>
                      </DocumentVisualization>
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setExhibitorsManualOpen}
                      modalIsOpen={exhibitorsManualOpen}
                      pdfLocation={data.exhibitorManualUrl}
                    />
                  </DocumentUpload>}
                  <DocumentUpload>
                    <DocumentUploadTitle>
                      {intl.formatMessage({ id: "pricing" })}
                    </DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      <DocumentVisualization>
                        <DocumentOpenText
                          onClick={() => {
                            if (data.pricingUrl) {
                              if (data?.contractCurrency === "BRL") {
                                handleLogFileDownload("PRICING_PT");
                              } else {
                                handleLogFileDownload("PRICING_EN");
                              }
                              setPricingTableOpen(true);
                            }
                          }}
                        >
                          {data.pricingUrl ? (
                            <FormattedMessage id="view_file" />
                          ) : (
                            <FormattedMessage id="file_is_not_configured" />
                          )}
                        </DocumentOpenText>
                      </DocumentVisualization>
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setPricingTableOpen}
                      modalIsOpen={pricingTableOpen}
                      pdfLocation={data.pricingUrl}
                    />
                  </DocumentUpload>
                  {/* Alteração de contorno visando resolver problema em produção com AGRISHOW, remover no futuro */}
                  {isAgrishow && <DocumentUpload>
                      <DocumentUploadTitle>
                        {intl.formatMessage({ id: "work_safety_manual" })}
                      </DocumentUploadTitle>
                      <DocumentDragAndDropContainer>
                        <DocumentVisualization>
                          <DocumentOpenText>
                            <a 
                              href={"https://drive.google.com/file/d/1eRJX9jlekVAuIfG0uBGkJOPHhIvffQhs/view"}
                              target={"blank"}
                            >
                              <FormattedMessage id="view_file" /> 
                            </a>
                          </DocumentOpenText>
                        </DocumentVisualization>
                      </DocumentDragAndDropContainer>
                    </DocumentUpload>
                  }
                </DocumentsUploadContainer>
              </DocumentsContainer>
            </FieldsContainer>
          </Session>
          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdPerson size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="exhibitor_info" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Horizontal>
                  <InputWithLabel
                    disabled
                    name="account_name"
                    value={data?.accountName}
                    label={intl.formatMessage({ id: "name" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    disabled
                    name="commercial_name"
                    value={data?.accountAlias}
                    label={intl.formatMessage({ id: "commercial_name" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    disabled
                    name="document"
                    value={data?.accountGovCompanyCode}
                    label={intl.formatMessage({ id: "document_cnpj" })}
                  />
                </Horizontal>
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdHome size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="booth" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Horizontal>
                  <SelectWithLabel
                    name="booth_select"
                    label={intl.formatMessage({
                      id: "select_a_booth",
                    })}
                    placeholder={intl.formatMessage({
                      id: "select_an_option",
                    })}
                    options={boothList}
                  />
                  <FormButtonContainer>
                    <FormButton
                      type="button"
                      loading={loading}
                      onClick={handleBoothVisualization}
                    >
                      <FormattedMessage id="visualize" />
                    </FormButton>
                  </FormButtonContainer>
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    disabled
                    name="booth_number"
                    value={boothSelected?.boothNumber}
                    label={intl.formatMessage({ id: "booth_number" })}
                  />
                  <InputWithLabel
                    disabled
                    name="area"
                    value={boothSelected?.area}
                    label={intl.formatMessage({ id: "area" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    disabled
                    name="booth_type"
                    value={boothSelected?.boothType}
                    label={intl.formatMessage({ id: "booth_type" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    name="headband"
                    validationBlur={validationBlurHeadband}
                    label={intl.formatMessage({ id: "front_sign" })}
                    maxLength="500"
                  />
                  <FormButtonContainer>
                    <FormButton
                      type="button"
                      loading={loading}
                      onClick={saveHeadband}
                    >
                      <FormattedMessage id="save" />
                    </FormButton>
                  </FormButtonContainer>
                </Horizontal>
              </FieldsContainer>
            </Session>
          </SessionDivider>
        </Container>
      </Form>
    </AnimatedMainContainer>
  );
};

export default Detail;
