import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import FormButton from "../../components/FormButton";
import SelectWithLabel from "../../components/SelectWithLabel";
import {
  Container,
  Content,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  InformationContainer,
  AlertMessage,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import Sidebar from "../../components/Sidebar";
import api from "../../service";
import Modal from "../../components/Modal";
import {
  MdAccountCircle,
  MdAssignment,
  MdAttachMoney,
  MdComputer,
  MdEdit,
  MdEvent,
  MdEventNote,
  MdInfo,
  MdModeEdit,
  MdPerson,
  MdDoneAll,
  MdCollectionsBookmark,
  MdDonutSmall,
  MdStar,
  MdSupervisorAccount,
} from "react-icons/md";
import Topbar from "../../components/Topbar";
import TermsModal from "../../components/AcceptTerms/TermsModal";
import { useHistory } from "react-router-dom";
import {
  FaFileContract,
  FaIdCardAlt,
  FaShoppingBasket,
  FaTachometerAlt,
  FaRegIdCard,
  FaDigitalTachograph,
  FaUserEdit,
  FaInfinity,
  FaCheck,
  FaWalking,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import {
  RiFileInfoLine,
  RiFileSearchFill,
  RiGroup2Fill,
  RiShieldUserFill,
  RiUserSettingsFill,
  RiVipDiamondFill,
  RiChatOffLine,
  RiBookmark2Line,
  RiSurveyLine,
  RiTeamFill,
} from "react-icons/ri";
import { SiOpenaccess } from "react-icons/si";
import { BiTransfer } from "react-icons/bi";
import { BsQuestionCircleFill } from 'react-icons/bs'
import {
  CgExtensionAdd,
  CgPlayListSearch,
  CgUserList,
  CgLoadbarDoc,
} from "react-icons/cg";
import { HiDocumentReport } from "react-icons/hi";

import {
  AiOutlineUsergroupAdd,
  AiFillShopping,
  AiOutlineSchedule,
  AiOutlineSearch,
} from "react-icons/ai";

import { GoGraph } from "react-icons/go";
import { useSidebar } from "../../hooks/sidebar";
import { useLoading } from "../../hooks/loading";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import * as Yup from "yup";
import LoadingOverlay from "../LoadingOverlay";
import { useToast } from "../../hooks/toast";
import getValidationErrors from "../../utils/getValidationErrors";
import EcommerceModal from "./EcommerceModal";

const MainContainerWithSidebar = ({ children }) => {
  const { user, token, accountCreated } = useSelector((state) => state.auth);
  const { isLoading, handleLoading } = useLoading();
  const { addToast } = useToast();
  const { sidebarIsVisible } = useSidebar();
  const intl = useIntl();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

  const [ecommerceModalOpen, setEcommerceModalOpen] = useState(false);
  const digitalInvitesFormRef = useRef(null);
  const [digitalInvitesModalOpen, setDigitalInvitesModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [opportunities, setOpportunities] = useState();

  const [digitalInvites, setDigitalInvites] = useState([]);
  const [digitalInvitesEventEditionCode, setDigitalInvitesEventEditionCode] =
    useState();

  const [accreditationSystemName, setAccreditationSystemName] = useState();

  const isAdministrator = user?.role?.type === "ADMINISTRATOR";
  const isExhibitor = user?.role?.type === "EXHIBITOR";

  const hasAccessDigitalInvitesSystemPermission = useMemo(
    () => {
      const dashboardCategory = user?.permissionCategories.find(
        (permission) => permission.code === 'SYSTEMS'
      );
      if (dashboardCategory?.permissions) {
        return dashboardCategory.permissions.some(
          (infoPermission) =>
            infoPermission.code === 'ACCESS_DIGITAL_INVITES_SYSTEM'
        );
      }
      return false;
    },
    [user]
  );

  const digitalInvitesSchema = Yup.object().shape({
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleDigitalInvitesAccess = useCallback(
    (event) => {
      handleLoading(true);
      setAccreditationSystemName(event.integrationType);
      setDigitalInvitesModalOpen(true);
      setDigitalInvitesEventEditionCode(event.eventEditionCode);

      api
        .get(`/exhibitor/opportunity/event/editionCode/${event.eventEditionCode}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => setOpportunities(response.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, handleLoading, intl, token]
  );

  useEffect(()=>{
    if(!isExhibitor || !hasAccessDigitalInvitesSystemPermission) return
    api
      .get("/exhibitor/events/by-integration", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const mappedDigitalInvitesMock = response.data.output.map(
          (event) => ({
            id: `ACCESS_${event.eventEditionCode}_DIGITAL_INVITES_SYSTEM`,
            name: intl.formatMessage(
              {
                id: "sidebar.access_digital_invites_system",
              },
              { event: event.label }
            ),
            code: "ACCESS_DIGITAL_INVITES_SYSTEM",
            icon: <MdStar size={19} />,
            clickFunction: () => handleDigitalInvitesAccess(event),
          })
        );
        setDigitalInvites(mappedDigitalInvitesMock)
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  },[isExhibitor, intl, addToast, token, handleLoading, handleDigitalInvitesAccess, hasAccessDigitalInvitesSystemPermission])

  const showConfirmation = () => {
    setModalIsOpen(true);
  };

  const generateHiddenInputAndAppend = (name, value, form) => {
    const field = document.createElement("input");
    field.type = "hidden";
    field.name = name;
    field.value = value;
    form.appendChild(field);
  };

  const handleFinancialSingleSignOn = () => {
    handleLoading(true);
    api
      .get(`/financial/access-information`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const { token, url } = result.data.financialGenerateTokenOutput;
        const form = document.createElement("form");
        form.method = "post";
        form.action = url;
        form.target = "_blank";

        generateHiddenInputAndAppend("bypass_key", token, form);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      })
      .catch((err) => {
        if (err.response.data.code === -22000) {
          addToast({
            type: "info",
            title: intl.formatMessage({
              id: "info",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        } else {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleLogout = () => {
    if (isAdministrator) {
      window.location.href =
        process.env.REACT_APP_SERVER_URL + "saml/logout?local=true";
    } else {
      window.location.href = process.env.REACT_APP_LOGOUT_URI;
    }
  };

  const handleEcommerceAccess = () => {
    if (accountCreated) {
      setEcommerceModalOpen(true);
    } else {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "complete_your_data",
        }),
      });
    }
  };

  const handleChecklistAccess = () => {
    history.push('/checklist')
  };

  const impersonateUserPermission = useMemo(
    () => ({
      id: "IMPERSONATE_USER",
      name: intl.formatMessage({
        id: "sidebar.impersonate_user",
      }),
      code: "IMPERSONATE_USER",
      icon: <MdSupervisorAccount size={19} />,
      clickFunction: () => history.push("/admin/impersonate-user"),
    }),
    [history, intl]
  );

  const handleDigitalInvitesSubmit = useCallback(
    async (data) => {
      try {
        digitalInvitesFormRef.current?.setErrors({});
        await digitalInvitesSchema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) return;

        handleLoading(true);

        api
          .post(
            "digital-invites/auth",
            {
              eventEditionCode: digitalInvitesEventEditionCode,
              opportunityId: data.opportunity,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            const {
              url,
              ev,
              i,
              cnpj,
              fantasyName,
              officialName,
              responsible,
              address,
              countryId,
              city,
              email,
              accountId,
              opportunityId,
              credentialCompanyName,
              footage,
              user,
              password,
            } = result.data.digitalInvitesGenerateTokenOutput;
            const form = document.createElement("form");
            form.method = "post";
            form.action = url;
            form.target = "_blank";

            generateHiddenInputAndAppend("USUARIO", user, form);
            generateHiddenInputAndAppend("SENHA", password, form);
            generateHiddenInputAndAppend("EV", ev, form);
            generateHiddenInputAndAppend("I", i, form);
            generateHiddenInputAndAppend("CNPJ", cnpj, form);
            generateHiddenInputAndAppend("NMFANTASIA", fantasyName, form);
            generateHiddenInputAndAppend("NMOFICIAL", officialName, form);
            generateHiddenInputAndAppend("RESPONSAVEL", responsible, form);
            generateHiddenInputAndAppend("ENDERECO", address, form);
            generateHiddenInputAndAppend("IDPAIS", countryId, form);
            generateHiddenInputAndAppend("CITY", city, form);
            generateHiddenInputAndAppend("EMAIL", email, form);
            generateHiddenInputAndAppend("BTS_CODCLIENTE", accountId, form);
            generateHiddenInputAndAppend(
              "BTS_CODCONTRATO",
              opportunityId,
              form
            );
            generateHiddenInputAndAppend(
              "NOMEEMPRESACREDENCIAL",
              credentialCompanyName,
              form
            );
            generateHiddenInputAndAppend("METRAGEM", footage, form);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
          })
          .catch((err) => {
            if (err.response.data.code === -5029) {
              addToast({
                type: "info",
                title: intl.formatMessage({
                  id: "info",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            } else {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            }
          })
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          digitalInvitesFormRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [digitalInvitesSchema, loading, handleLoading, token, addToast, intl]
  );

  const digitalInvitesValidationBlur = (name) => {
    let errors = digitalInvitesFormRef.current.getErrors();
    validateSingleFieldOnBlur(
      errors,
      digitalInvitesSchema,
      name,
      digitalInvitesFormRef
    );
  };

  return (
    <LoadingOverlay>
      <Container sidebarIsVisible={sidebarIsVisible}>
        {sidebarIsVisible && (
          <Sidebar
            checkablePermission={impersonateUserPermission}
            data={[
              {
                id: "CHECKLIST",
                name: intl.formatMessage({ id: "sidebar.checkist" }),
                icon: <FaCheck size={38} color="#cccccc" />,
                code: "CHECKLIST",
                clickFunction: () => handleChecklistAccess(),
                subitem: [],
                standalone: true,
                routeName: ["/checklist"],
              },
              {
                id: "BILLS",
                name: intl.formatMessage({ id: "sidebar.bills" }),
                icon: <MdAttachMoney size={46} color="#cccccc" />,
                code: "BILLS",
                clickFunction: () => handleFinancialSingleSignOn(),
                subitem: [],
                standalone: true,
              },
              {
                id: "ACCOUNTS_USERS",
                name: intl.formatMessage({ id: "sidebar.accounts_and_users" }),
                icon: <MdPerson size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({
                  id: "sidebar.accounts_and_users",
                }),
                code: "ACCOUNTS_USERS",
                routeName: [
                  "users",
                  "profiles",
                  "accounts",
                  "/account",
                  "service-providers-management",
                  "admin/impersonate-user",
                ],
                subitem: [
                  {
                    id: "ACCESS_ADMIN_TERMS_OF_USE",
                    name: intl.formatMessage({
                      id: "sidebar.access_terms_of_use",
                    }),
                    code: "ACCESS_ADMIN_TERMS_OF_USE",
                    icon: <MdAssignment size={19} />,
                    clickFunction: () => setTermsAndConditionsOpen(true),
                  },
                  {
                    id: "LIST_USERS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_users",
                    }),
                    code: "LIST_USERS",
                    icon: <RiUserSettingsFill size={19} />,
                    clickFunction: () => history.push("/users"),
                  },
                  {
                    id: "SHOW_ROLES",
                    name: intl.formatMessage({
                      id: "sidebar.manage_user_profile",
                    }),
                    code: "SHOW_ROLES",
                    icon: <RiShieldUserFill size={19} />,
                    clickFunction: () => history.push("/profiles"),
                  },
                  {
                    id: "SHOW_ACCOUNTS_INFORMATIONS",
                    name: intl.formatMessage({
                      id: "sidebar.search_exhibitors",
                    }),
                    code: "SHOW_ACCOUNTS_INFORMATIONS",
                    icon: <CgPlayListSearch size={19} />,
                    clickFunction: () => history.push("/accounts"),
                  },
                  {
                    id: "SHOW_SERVICE_PROVIDERS_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.manage_providers",
                    }),
                    code: "SHOW_SERVICE_PROVIDERS_INFORMATION",
                    icon: <MdModeEdit size={19} />,
                    clickFunction: () =>
                      history.push("/service-providers-management"),
                  },
                  {
                    id: "MANAGE_STAFF_ACCESS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_staff_access",
                    }),
                    code: "MANAGE_STAFF_ACCESS",
                    icon: <RiTeamFill size={19} />,
                    clickFunction: () => history.push("/staff-access"),
                  },
                  impersonateUserPermission,
                ],
              },
              {
                id: "PROFILE",
                name: intl.formatMessage({
                  id: "sidebar.my_account",
                }),
                icon: <MdPerson size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({ id: "sidebar.my_account" }),
                code: "PROFILE",
                routeName: [
                  "update-user",
                  "salesforce/user",
                ],
                subitem: [
                  {
                    id: "ACCESS_TERMS_OF_USE",
                    name: intl.formatMessage({
                      id: "sidebar.access_terms_of_use",
                    }),
                    code: "ACCESS_TERMS_OF_USE",
                    icon: <FaFileContract size={19} />,
                    clickFunction: () => setTermsAndConditionsOpen(true),
                  },
                  {
                    id: "EDIT_PROFILE_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.my_data",
                    }),
                    code: "EDIT_PROFILE_INFORMATION",
                    icon: <MdPerson size={19} />,
                    clickFunction: () => history.push("/update-user"),
                  },
                  {
                    id: "EDIT_EXHIBITOR_PROFILE_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.my_data",
                    }),
                    code: "EDIT_EXHIBITOR_PROFILE_INFORMATION",
                    icon: <MdAccountCircle size={19} />,
                    clickFunction: () => history.push("/salesforce/user"),
                  },
                ],
              },
              {
                id: "EVENTS",
                name: intl.formatMessage({ id: "sidebar.events" }),
                icon: <MdEvent size={46} color="#cccccc" />,
                code: "EVENTS",
                subitemTitle: intl.formatMessage({ id: "sidebar.events" }),
                routeName: [
                  "events",
                  "contracts",
                  "contract",
                  "events-exhibitor-visualization",
                  "admin/additional-products",
                  "admin/categories",
                  "admin/product-event-association",
                  "serviceprovider/association-provider-view",
                  "serviceprovider-association",
                  "manage-salesforce-event-editions",
                  "consult-provider-by-event",
                  "invoices"
                ],
                subitem: [
                  {
                    id: "ACCESS_EVENTS_INFORMATION",
                    name: intl.formatMessage({ id: "sidebar.event_select" }),
                    code: "ACCESS_EVENTS_INFORMATION",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () =>
                      history.push("/events-exhibitor-visualization"),
                  },
                  {
                    id: "UPDATE_EVENT",
                    name: intl.formatMessage({
                      id: "sidebar.manage_event_editions",
                    }),
                    code: "UPDATE_EVENT",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () => history.push("/events"),
                  },
                  {
                    id: "EDIT_SERVICE_PROVIDER_ASSOCIATION",
                    name: intl.formatMessage({
                      id: "sidebar.associate_service_provider",
                    }),
                    code: "EDIT_SERVICE_PROVIDER_ASSOCIATION",
                    icon: <AiOutlineUsergroupAdd size={19} />,
                    clickFunction: () =>
                      history.push("/serviceprovider-association"),
                  },
                  {
                    id: "SHOW_OPPORTUNITIES_INFORMATIONS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_contracts",
                    }),
                    code: "SHOW_OPPORTUNITIES_INFORMATIONS",
                    icon: <RiFileSearchFill size={19} />,
                    clickFunction: () => history.push("/contracts"),
                  },
                  {
                    id: "CREATE_PRODUCTS_CATEGORIES",
                    name: intl.formatMessage({
                      id: "sidebar.manage_categories",
                    }),
                    code: "CREATE_PRODUCTS_CATEGORIES",
                    icon: <CgLoadbarDoc size={19} />,
                    clickFunction: () => history.push("/admin/categories"),
                  },
                  {
                    id: "CREATE_PRODUCTS",
                    name: intl.formatMessage({
                      id: "sidebar.create_additional_products",
                    }),
                    code: "CREATE_PRODUCTS",
                    icon: <CgExtensionAdd size={19} />,
                    clickFunction: () =>
                      history.push("/admin/additional-products"),
                  },
                  {
                    id: "ASSOCIATE_PRODUCTS_WITH_EVENTS",
                    name: intl.formatMessage({
                      id: "sidebar.associate_products_with_events",
                    }),
                    code: "ASSOCIATE_PRODUCTS_WITH_EVENTS",
                    icon: <HiDocumentReport size={19} />,
                    clickFunction: () =>
                      history.push("/admin/product-event-association"),
                  },
                  {
                    id: "ADMIN_SERVICE_PROVIDER_ASSOCIATION",
                    name: intl.formatMessage({
                      id: "sidebar.associate_service_provider",
                    }),
                    code: "ADMIN_SERVICE_PROVIDER_ASSOCIATION",
                    icon: <CgLoadbarDoc size={19} />,
                    clickFunction: () =>
                      history.push("/admin/serviceprovider-association"),
                  },
                  {
                    id: "SHOW_EVENTS_ASSOCIATE_WITH_PROVIDER",
                    name: intl.formatMessage({ id: "sidebar.event_select" }),
                    code: "SHOW_EVENTS_ASSOCIATE_WITH_PROVIDER",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () =>
                      history.push(
                        "/serviceprovider/association-provider-view"
                      ),
                  },
                  {
                    id: "CONSULT_SERVICE_PROVIDER_ASSOCIATIONS",
                    name: intl.formatMessage({ id: "sidebar.consult_provider_by_event" }),
                    code: "CONSULT_SERVICE_PROVIDER_ASSOCIATIONS",
                    icon: <AiOutlineSearch size={20} />,
                    clickFunction: () =>
                      history.push(
                        "/consult-provider-by-event"
                      ),
                  },
                  {
                    id: "MANAGE_SALESFORCE_EVENT_EDITIONS",
                    name: intl.formatMessage({ id: "sidebar.manage_salesforce_event_editions" }),
                    code: "MANAGE_SALESFORCE_EVENT_EDITIONS",
                    icon: <MdEventNote size={29} />,
                    clickFunction: () =>
                      history.push(
                        "/manage-salesforce-event-editions"
                      ),
                  },
                  {
                    id: "MANAGE_INVOICE",
                    name: intl.formatMessage({ id: "sidebar.invoices" }),
                    code: "MANAGE_INVOICE",
                    icon: <FaFileInvoiceDollar size={16} />,
                    clickFunction: () =>
                      history.push("/invoices"),
                  },
                ],
              },
              {
                id: "ACCREDITATION",
                name: intl.formatMessage({
                  id: "sidebar.credentials",
                }),
                subitemTitle: intl.formatMessage({
                  id: "sidebar.credentials",
                }),
                icon: <FaIdCardAlt size={46} color="#cccccc" />,
                code: "ACCREDITATION",
                routeName: [
                  "credentials",
                  "admin/manage-guests",
                  "manage-credentials",
                  "credential/associate",
                  "credential/schedule-withdrawal",
                  "credential-transfer",
                  "admin-credential-transfer",
                  "admin/associate-collaborators",
                  "associate-collaborators",
                  "admin/associate-collaborator",
                  "associate-collaborator",
                ],
                subitem: [
                  {
                    id: "MANAGE_GUESTS_ADMIN",
                    code: "MANAGE_GUESTS_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.manage_guests",
                    }),
                    icon: <RiGroup2Fill size={19} />,
                    clickFunction: () => history.push("/admin/manage-guests"),
                  },
                  {
                    id: "MANAGE_CREDENTIALS_ADMIN",
                    code: "MANAGE_CREDENTIALS_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.manage_credentials",
                    }),
                    icon: <SiOpenaccess size={19} />,
                    clickFunction: () => history.push("/manage-credentials"),
                  },
                  {
                    id: "MANAGE_CREDENTIALS",
                    code: "MANAGE_CREDENTIALS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_credentials",
                    }),
                    icon: <SiOpenaccess size={19} />,
                    clickFunction: () => history.push("/credentials"),
                  },
                  {
                    id: "TRANSFER_CREDENTIALS",
                    code: "TRANSFER_CREDENTIALS",
                    name: intl.formatMessage({
                      id: "sidebar.transfer_credentials",
                    }),
                    icon: <BiTransfer size={19} />,
                    clickFunction: () => history.push("/credential-transfer"),
                  },
                  {
                    id: "VIEW_TRANSFERED_CREDENTIALS_ADMIN",
                    code: "VIEW_TRANSFERED_CREDENTIALS_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.transfer_credentials",
                    }),
                    icon: <BiTransfer size={19} />,
                    clickFunction: () => history.push("/admin-credential-transfer"),
                  },
                  {
                    id: "MANAGE_ASSOCIATE_COLLABORATOR_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.register_collaborator",
                    }),
                    code: "MANAGE_ASSOCIATE_COLLABORATOR_ADMIN",
                    icon: <AiOutlineUsergroupAdd size={19} />,
                    clickFunction: () =>
                      history.push("/admin/associate-collaborators"),
                  },
                  {
                    id: "MANAGE_ASSOCIATE_COLLABORATOR",
                    name: intl.formatMessage({
                      id: "sidebar.register_collaborator",
                    }),
                    code: "MANAGE_ASSOCIATE_COLLABORATOR",
                    icon: <AiOutlineUsergroupAdd size={19} />,
                    clickFunction: () =>
                      history.push("/associate-collaborators"),
                  },
                ],
              },
              {
                id: "SERVICE_PROVIDER_EVENTS",
                name: intl.formatMessage({ id: "sidebar.events" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.events" }),
                icon: <MdEvent size={46} color="#cccccc" />,
                code: "SERVICE_PROVIDER_EVENTS",
                routeName: ["servicerprovider_association_provider_view"],
                clickFunction: () =>
                  history.push("/serviceprovider/association-provider-view"),
                subitem: [],
              },
              {
                id: "REPORTS",
                name: intl.formatMessage({ id: "sidebar.reports" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.reports" }),
                icon: <GoGraph size={46} color="#cccccc" />,
                code: "REPORTS",
                routeName: [
                  "servicerprovider_association_provider_view",
                  "admin/guests-report",
                  "logs",
                  "admin/credential-schedule-report",
                  "admin/credential-report",
                  "/admin/exhibitors-associated-credential-synthetic",
                  "/admin/exhibitors-access-by-event-report",
                  "/admin/exhibitors-didnt-create-credential-report",
                  "/admin/providers-associated-report",
                  "/admin/digital-products-report",
                  "/admin/headband-report",
                  "/admin/order-report",
                  "/admin/service-providers-report",
                  "/admin/action-control-and-event-indicators-report",
                  "/admin/digital-platform-integration-report",
                ],
                subitem: [
                  {
                    id: "SHOW_LOGS",
                    name: intl.formatMessage({ id: "sidebar.access_history" }),
                    code: "SHOW_LOGS",
                    icon: <CgUserList size={19} />,
                    clickFunction: () => history.push("/logs"),
                  },
                  {
                    id: "ACCESS_EXHIBITORS_DID_NOT_ACCESS_EVENT_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.exhibitors_access_by_event_report",
                    }),
                    code: "ACCESS_EXHIBITORS_DID_NOT_ACCESS_EVENT_REPORT",
                    icon: <RiChatOffLine size={26} />,
                    clickFunction: () =>
                      history.push("/admin/exhibitors-access-by-event-report"),
                  },
                  {
                    id: "ACCESS_ACTION_CONTROL_AND_EVENT_INDICATORS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.customers_journey_report",
                    }),
                    code: "ACCESS_ACTION_CONTROL_AND_EVENT_INDICATORS_REPORT",
                    icon: <RiTeamFill size={19} />,
                    clickFunction: () =>
                      history.push("/admin/action-control-and-event-indicators-report"),
                  },
                  {
                    id: "ACCESS_ORDER_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.order_report",
                    }),
                    code: "ACCESS_ORDER_REPORT",
                    icon: <AiFillShopping size={19} />,
                    clickFunction: () => history.push("/admin/order-report"),
                  },
                  {
                    id: "ACCESS_HEADBAND_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.front_sign_report",
                    }),
                    code: "ACCESS_HEADBAND_REPORT",
                    icon: <RiBookmark2Line size={19} />,
                    clickFunction: () => history.push("/admin/headband-report"),
                  },

                  {
                    id: "ACCESS_SERVICE_PROVIDERS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.service_providers_report",
                    }),
                    code: "ACCESS_SERVICE_PROVIDERS_REPORT",
                    icon: <MdDonutSmall size={19} />,
                    clickFunction: () =>
                      history.push("/admin/service-providers-report"),
                  },

                  {
                    id: "ACCESS_SERVICE_PROVIDERS_ASSOCIATED_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.service_providers_associated_report",
                    }),
                    code: "ACCESS_SERVICE_PROVIDERS_ASSOCIATED_REPORT",
                    icon: <MdDoneAll size={30} />,
                    clickFunction: () =>
                      history.push("/admin/providers-associated-report"),
                  },
                  {
                    id: "CREDENTIAL_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.credential_report",
                    }),
                    code: "CREDENTIAL_REPORT",
                    icon: <FaIdCardAlt size={19} />,
                    clickFunction: () =>
                      history.push("/admin/credential-report"),
                  },
                  {
                    id: "ACCESS_EXHIBITORS_ASSOCIATED_CREDENTIALS_SYNTHETIC_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.exhibitors_associated_credentials_synthetic_report",
                    }),
                    code: "ACCESS_EXHIBITORS_ASSOCIATED_CREDENTIALS_SYNTHETIC_REPORT",
                    icon: <FaRegIdCard size={27} />,
                    clickFunction: () =>
                      history.push(
                        "/admin/exhibitors-associated-credential-synthetic"
                      ),
                  },

                  {
                    id: "ACCESS_EXHIBITORS_DID_NOT_CREATE_CREDENTIAL_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.exhibitors_didnt_create_credentials_report",
                    }),
                    code: "ACCESS_EXHIBITORS_DID_NOT_CREATE_CREDENTIAL_REPORT",
                    icon: <FaRegIdCard size={32} />,
                    clickFunction: () =>
                      history.push(
                        "/admin/exhibitors-didnt-create-credential-report"
                      ),
                  },
                  {
                    id: "SCHEDULING_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.scheduling_report",
                    }),
                    code: "SCHEDULING_REPORT",
                    icon: <AiOutlineSchedule size={19} />,
                    clickFunction: () =>
                      history.push("/admin/credential-schedule-report"),
                  },
                  {
                    id: "ACCESS_GUESTS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.guests_report",
                    }),
                    code: "ACCESS_GUESTS_REPORT",
                    icon: <RiVipDiamondFill size={19} />,
                    clickFunction: () => history.push("/admin/guests-report"),
                  },
                  {
                    id: "ACCESS_DIGITAL_PRODUCTS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.digital_products_report",
                    }),
                    code: "ACCESS_DIGITAL_PRODUCTS_REPORT",
                    icon: <FaDigitalTachograph size={19} />,
                    clickFunction: () =>
                      history.push("/admin/digital-products-report"),
                  },
                  {
                    id: "ACCESS_SWAPCARD_INTEGRATION_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.swapcard_integration_report",
                    }),
                    code: "ACCESS_SWAPCARD_INTEGRATION_REPORT",
                    icon: <FaInfinity size={19} />,
                    clickFunction: () =>
                      history.push("/admin/digital-platform-integration-report"),
                  },
                ],
              },
              {
                id: "ECOMMERCE",
                name: intl.formatMessage({ id: "sidebar.ecommerce" }),
                icon: <FaShoppingBasket size={46} color="#cccccc" />,
                code: "ECOMMERCE",
                clickFunction: () => handleEcommerceAccess(),
                subitem: [],
                standalone: true,
              },
              {
                id: "COCKPIT",
                name: intl.formatMessage({ id: "sidebar.cockpit" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.cockpit" }),
                icon: <FaTachometerAlt size={46} color="#cccccc" />,
                code: "COCKPIT",
                routeName: [
                  "cockpit",
                  "/admin/event-setup",
                  "/admin/event-setup-helpdesk",
                  "/admin/update-registration-data",
                  "/admin/search-journey"
                ],
                subitem: [
                  {
                    id: "SHOW_COCKPIT",
                    name: "Dashboard",
                    code: "SHOW_COCKPIT",
                    icon: <FaTachometerAlt size={19} />,
                    clickFunction: () => history.push("/cockpit"),
                  },
                  {
                    id: "MANAGE_EVENT_SETUP",
                    name: intl.formatMessage({
                      id: "sidebar.event_setup",
                    }),
                    code: "MANAGE_EVENT_SETUP",
                    icon: <MdCollectionsBookmark size={19} />,
                    clickFunction: () => history.push("/admin/event-setup"),
                  },
                  {
                    id: "MANAGE_EVENT_SETUP_HELPDESK",
                    name: intl.formatMessage({
                      id: "sidebar.event_setup_helpdesk",
                    }),
                    code: "MANAGE_EVENT_SETUP_HELPDESK",
                    icon: <RiSurveyLine size={19} />,
                    clickFunction: () =>
                      history.push("/admin/event-setup-helpdesk"),
                  },
                  {
                    id: "UPDATE_REGISTRATION_DATA_SERPRO",
                    name: intl.formatMessage({
                      id: "sidebar.update_registration_data",
                    }),
                    code: "UPDATE_REGISTRATION_DATA_SERPRO",
                    icon: <FaUserEdit size={19} />,
                    clickFunction: () =>
                      history.push("/admin/update-registration-data"),
                  },
                  {
                    id: "SEARCH_ACCOUNT_JOURNEY",
                    name: intl.formatMessage({
                      id: "sidebar.account_journey",
                    }),
                    code: "SEARCH_ACCOUNT_JOURNEY",
                    icon: <FaWalking size={20} />,
                    clickFunction: () =>
                      history.push("/admin/search-journey"),
                  },
                  
                ],
                standalone: true,
              },
              {
                id: "SYSTEMS",
                name: intl.formatMessage({ id: "sidebar.exhibitors_guest" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.exhibitors_guest" }),
                icon: <MdComputer size={46} color="#cccccc" />,
                code: "SYSTEMS",
                subitem: [
                  ...digitalInvites,
                ],
              },
              {
                id: "FAQ",
                name: intl.formatMessage({ id: "FAQ" }),
                icon: <BsQuestionCircleFill size={40} color="#cccccc" />,
                subitemTitle: intl.formatMessage({ id: "FAQ" }),
                code: "FAQ",
                routeName: ["faq", "faq-visualization"],
                subitem: [
                  {
                    id: "EDIT_KNOWLEDGE_BASE",
                    name: intl.formatMessage({
                      id: "sidebar.edit_knowledge_base",
                    }),
                    code: "EDIT_KNOWLEDGE_BASE",
                    icon: <MdEdit size={19} />,
                    clickFunction: () => history.push("/faq"),
                  },
                  {
                    id: "ACCESS_KNOWLEDGE_BASE",
                    name: intl.formatMessage({ id: "FAQ" }),
                    code: "ACCESS_KNOWLEDGE_BASE",
                    icon: <RiFileInfoLine size={19} />,
                    clickFunction: () =>
                      history.push("/faq-visualization"),
                  },
                ]
              },
              {
                id: "CONTACT",
                name: intl.formatMessage({ id: "contact" }),
                icon: <MdInfo size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({ id: "contact" }),
                code: "CONTACT",
                routeName: ["contact"],
                subitem: [
                  {
                    id: "info-item",
                    code: "NOTIFICATIONS_CENTER",
                    clickFunction: () => null,
                  },
                ],
              },
            ]}
            openConfirmation={showConfirmation}
          />
        )}

        <Topbar />
        <Content sidebarIsVisible={sidebarIsVisible}>{children}</Content>
        <ConfirmationModal
          setIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          confirmationFunction={handleLogout}
          description={intl.formatMessage({ id: "logout_confirmation" })}
          confirmationText={intl.formatMessage({ id: "yes" })}
          cancelText={intl.formatMessage({ id: "no" })}
          isLoading={isLoading}
        />
        <TermsModal
          setIsOpen={setTermsAndConditionsOpen}
          modalIsOpen={termsAndConditionsOpen}
        />
        <EcommerceModal
          setIsOpen={setEcommerceModalOpen}
          modalIsOpen={ecommerceModalOpen}
        >
        </EcommerceModal>
        <Modal
          setIsOpen={setDigitalInvitesModalOpen}
          modalIsOpen={
            digitalInvitesModalOpen && digitalInvitesEventEditionCode
          }
          modalWidth="50%"
          overflowX="auto"
        >
          <FormContainer>
            <Form
              ref={digitalInvitesFormRef}
              onSubmit={handleDigitalInvitesSubmit}
            >
              <TitleContainer>
                <Title>
                  <FormattedMessage id="access_accreditation_invitation" />
                </Title>
              </TitleContainer>
              <FieldsContainer>
                <SelectWithLabel
                  name="opportunity"
                  validationBlur={digitalInvitesValidationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={opportunities}
                />
                <InformationContainer>
                  <AlertMessage>
                    {intl.formatMessage(
                      { id: "access_accreditation_invitation_message" },
                      { systemName: accreditationSystemName }
                    )}
                  </AlertMessage>
                </InformationContainer>
                <Horizontal>
                  <FilterActionsContainer>
                    <SubmitContainer>
                      <FormButton
                        color="#C9C9C9"
                        type="button"
                        onClick={() => setDigitalInvitesModalOpen(false)}
                        loading={isLoading}
                      >
                        <FormattedMessage id="close" />
                      </FormButton>
                    </SubmitContainer>
                    <SubmitContainer>
                      <FormButton type="submit" loading={loading}>
                        <FormattedMessage id="accreditation_system" />
                      </FormButton>
                    </SubmitContainer>
                  </FilterActionsContainer>
                </Horizontal>
              </FieldsContainer>
            </Form>
          </FormContainer>
        </Modal>
      </Container>
    </LoadingOverlay>
  );
};

export default MainContainerWithSidebar;
